import React,{ useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom"
import { UserContext } from "../../context/theme";
import 'iconify-icon';



const DropDownMenu = (props) =>{
    const {children} = props ;
    // console.log(children)
    return(
        <>
            <div className="sidebarMenu">
                <ul className="metismenu">
                    {children}
                </ul>
            </div>
        </>
    )
}

const Dropdown = (props) =>{
    const { pathname } = useLocation();
    const {children, title, icon,iconifyIcon , img} = props ;
    let matchPath = pathname || '';
    let iconMenu = icon || 'icon-user';
    let iconfy = iconifyIcon || '';
    let target = title.replace(/ /g, "_") || ''
    let arrCheck = Array.isArray(children);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const pathFilter = arrCheck && children.filter((d) => {
        const pathParts = d.props.path.split('/');
        const matchPathParts = matchPath.split('/');
        return pathParts[1] === matchPathParts[1];
    });
    useEffect(() => {
        const collapsedState = localStorage.getItem(`${title}_isCollapsed`);
        setIsCollapsed(collapsedState === 'false' ? false : true);
    }, [pathname, title]);

    const toggleCollapse = () => {
        const newCollapsedState = !isCollapsed;
        setIsCollapsed(newCollapsedState);
        localStorage.setItem(`${title}_isCollapsed`, newCollapsedState.toString());
    };
    // console.log("pathLength",pathFilter.length)
    // console.log("iscollapsed",isCollapsed)
    return(
        <>
            <li>
            <Link
                    className={`btn-toggle align-items-center ${isCollapsed && (pathFilter.length > 0) ? '':'collapsed'}`}
                    to="#"
                    onClick={toggleCollapse}
                    data-bs-toggle="collapse"
                    title="Tables"
                    data-bs-target={`#${target}`}
                    aria-expanded={!isCollapsed ? "true" : "false"}
                >
                {iconifyIcon && <span><iconify-icon icon={iconMenu} /></span>}
                {!iconifyIcon && iconMenu && <span className={iconMenu}></span>}
                {img && <span><img src={img} alt={title} /></span>}
                <span className="menu_txt">{title}</span>
            </Link>
            <ul className={`collapse has_menu ${!isCollapsed &&  (pathFilter.length > 0)  ? 'show' : ''}`} id={target}>
                {children}
            </ul>
        </li>
        </>
    )
}

const Menu = (props) =>{
    const { pathname } = useLocation();
    const {children, path, icon,title, iconifyIcon} = props ;
    let matchPath = path || '/404';
    let iconMenu = icon || '';
    let iconfy = iconifyIcon || '';
    const {setBreadcrumbs} = useContext(UserContext); 
    useEffect(() => {
        let pageInfo = {
            link: path,
            title: title
        };
        if (pathname === matchPath) {
            setBreadcrumbs(pageInfo);
        }
    }, [pathname, matchPath, setBreadcrumbs]);

    const pathnameParts = pathname.split('/');
    const matchPathParts = matchPath.split('/');
    const isActive = pathnameParts[1] === matchPathParts[1];
    return (
        <li>
       <Link to={matchPath} className={`has_menu ${isActive ? 'active' : ''}`}>
                {iconfy ? (
                    <span><iconify-icon icon={iconMenu} /></span>
                ) : (
                    <span className={`${iconMenu}`}></span>
                )}
                <span className="menu_txt">{title}</span>
            </Link>
        </li>
    );
};

export {Dropdown,Menu}
export default DropDownMenu