import React, { useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import { UserContext } from "../context/theme";


function Header() {
    const { reset, counts } = useContext(UserContext);
    const navigate = useNavigate();
    return (
        <>
            <div className="left-sidebar-wrapper">
                <nav className="sidebar">
                    <div className="sidebar-header text-center">
                        <NavLink activeclassname="isActive" to="/">
                            MK ADMIN
                        </NavLink>
                        <strong>
                            <NavLink activeclassname="isActive" to="/">
                                SP
                            </NavLink>
                        </strong>
                    </div>
                    <div className="left-nav-menu">
                        <DropDownMenu>
                        <Dropdown title={`Dashboard`} icon="ic:baseline-dashboard" iconifyIcon="true">
                               
                            <Menu path="/" title="Fig Trade" iconifyIcon="true"></Menu>
                            <Menu path="/figpedia" title="Fig Pedia" iconifyIcon="true"></Menu>
                            <Menu path="/ourfigs" title="Our Figs" iconifyIcon="true"></Menu>
                            </Dropdown>
                            <Dropdown title={`Users`} icon="tabler:user" iconifyIcon="true">
                                <Menu path="/users-sellers/list/1" title="All Users"></Menu>
                                <Menu path="/userlogs/1" title="User Logs"></Menu>
                                <Menu path="/reported-users" title="Reported Users"></Menu>
                                <Menu path="/user-moderators/list/1" title="Moderator"></Menu>
                                <Menu path="/board-member" title="Board Members"></Menu>
                                {/* <Menu path="/users/users-login" title={`User Login Details`}></Menu> */}
                                {/* <Menu path="/users/seller-request" title={`Seller Request`}></Menu> */}
                                {/* <Menu path="/users/products-listed" title={`Products Listed`}></Menu> */}
                                {/* <Menu path="/users/store-subscriptions" title={`Store And Subscriptions`}></Menu> */}
                                {/* <Menu path="/users/invoicing" title={`Invoicing`}></Menu> */}
                                {/* <Menu path="/users/addresses-added" title={`Addresses Added`}></Menu> */}
                                {/* <Menu path="/users/badges" title={`Badges`}></Menu> */}
                            </Dropdown>
                            {/* <Dropdown title={`Invoice`} icon="uil:invoice" iconifyIcon="true"> */}
                                <Menu path="/users/invoicing" title={`Site Billing`} icon="uil:invoice" iconifyIcon="true"></Menu>
                                {/* <Menu path="/users/invoicing" title={`Packed Invoice`}></Menu> */}
                                {/* <Menu path="/users/invoicing" title={`Shipped Invoice`}></Menu> */}
                            {/* </Dropdown> */}
                            {/* <Menu path="/users/orders-placed" title={`Orders Placed`} icon="ion:cart" iconifyIcon="true"></Menu> */}
                            {/* <Menu path="/users/advertisements" title={`Advertisements`} icon="ri:advertisement-line" iconifyIcon="true"></Menu> */}
                            {/* <Menu path="/users/auctions-participated" title={`Auctions Participated`} icon="mingcute:auction-line" iconifyIcon="true"></Menu> */}
                            {/* <Dropdown title={`Listing`} icon="ic:baseline-list" iconifyIcon="true">
                                <Menu path="/listing/view-listings" title={`View All Listings`}></Menu>
                                <Menu path="/listing/listing-flags" title={`Listing Flags`}></Menu>
                            </Dropdown> */}
                            {/* <Dropdown title={`Orders`} icon="ion:cart" iconifyIcon="true">
                                <Menu path="/orders/ongoing-orders" title={`Ongoing Orders`}></Menu>
                                <Menu path="/orders/cancelled-orders" title={`Cancelled Orders`}></Menu>
                                <Menu path="/orders/returned-orders" title={`Returned Orders`}></Menu>
                            </Dropdown> */}
                            {/* <Dropdown title={`Fees Management`} icon="eos-icons:cluster-management-outlined" iconifyIcon="true">
                                <Menu path="/fees-management/store-manage" title={`Store Fee Manage`}></Menu>
                                <Menu path="/fees-management/margin-manage" title={`Margin Manage`}></Menu>
                                <Menu path="/fees-management/advertisement-manage/1" title={`Advertisement Fee Manage`}></Menu>
                            </Dropdown>
                            <Dropdown title={`Communications`} icon="mdi:near-field-communication-tap" iconifyIcon="true">
                                <Menu path="/communications/category" title={`Notifications`}></Menu>
                                <Menu path="/procommunicationsduct/category" title={`Emails`}></Menu>
                                <Menu path="/communications/category" title={`Chat`}></Menu>
                            </Dropdown>
                            <Dropdown title={`Country Assignments`} icon="subway:world-1" iconifyIcon="true">
                                <Menu path="/country-assignments/list" title={`Country List`}></Menu>
                            </Dropdown> */}

                            {/* <Menu path="/reports/reports" title="Reports" icon="material-symbols:partner-reports" iconifyIcon="true"></Menu> */}

                            {/* <Dropdown title={`Store`} icon="streamline:store-1" iconifyIcon="true">
                                <Menu path="/store/list-stores" title={`List of Stores`}></Menu>
                                <Menu path="/store/edit-store" title={`Edit Store`}></Menu>
                            </Dropdown>
                            <Dropdown title={`Forum`} icon="mdi-light:forum" iconifyIcon="true">
                                <Menu path="/forum/manage-posts" title={`Manage Posts`}></Menu>
                                <Menu path="/forum/create-polls" title={`Create/manage Polls`}></Menu>
                            </Dropdown>
                            <Menu path="/database-controls/database-controls" title="Database Controls" icon="octicon:database-24" iconifyIcon="true"></Menu> */}
                            <Dropdown title={`Fig Trade Management`} icon="simple-icons:frontendmentor" iconifyIcon="true">
                               <Menu path="/category/list/1" title={`Category`}></Menu>
                                <Menu path="/Shipping-gateways/list/1" title={`Shipping Companies`}></Menu>
                                <Menu path="/badges/list/1" title={`Badges`}></Menu>
                                <Menu path="/banner/list/1" title={`Banner`}></Menu>
                                <Menu path="/cultivar/list/1" title={`Cultivar`}></Menu>
                                <Menu path="/listing-management/list/1" title={`Listing Management`}></Menu>
                                <Menu path="/invoices/list/1" title={`Invoices`}></Menu>
                                <Menu path="/price-management/list/1" title={`Price Management`}></Menu>
                            </Dropdown>

                            <Dropdown title={`Our figs management`} icon="mdi:user-supervisor" iconifyIcon="true">
                                <Menu path="/channel/list/1" title={`Channels`}></Menu>
                                <Menu path="/sub-forum/list/1" title={`Sub-Forums`}></Menu>
                                <Menu path="/forumcontent/edit/home" title={`Forum Content`}></Menu>
                                <Menu path="/topic/list/1" title={`Topics`}></Menu>
                                <Menu path="/Flagged-Activities" title={`Flagged Activities`}></Menu>
                            </Dropdown>

                            <Menu path="/fig-pedia-content/edit/home" title={`Fig Pedia manage`} icon="mdi:account-settings" iconifyIcon="true"></Menu>

                            <Dropdown title={`My Figs manage`} icon="mdi:person" iconifyIcon="true">
                                <Menu path="/voted-cultivars" title={`Voted cultivars`}></Menu>
                            </Dropdown>

                            <Dropdown title={` E-commerce store`} icon="mdi:local-grocery-store" iconifyIcon="true">
                            <Menu path="/subscription-plan/list/1" title={`Subscription Plans`}></Menu>
                                <Menu path="/purchase-history" title={`Purchase History`}></Menu>
                                <Menu path="/advertisement" title={`Advertisements`}></Menu>
                            </Dropdown>

                            <Menu path="/communnication" title={`Communication`}   icon="mdi:near-field-communication-tap" iconifyIcon="true"></Menu>

                            <Dropdown title={`Settings`} icon="mdi:settings" iconifyIcon="true">
                                <Menu path="/Country/list/1" title={`Country`}></Menu>
                                <Menu path="/State/list/1" title={`State`}></Menu>
                                <Menu path="/city/list/1" title={`City`}></Menu>
                            </Dropdown>

                            <Dropdown title={`Frontend CMS`} icon="mdi:user-box" iconifyIcon="true">
                                <Menu path="/homepage" title={`Homepage`}></Menu>
                                <Menu path="/our-figs" title={`Our Figs`}></Menu>
                                {/* <Menu path="/fig-pedia-content/edit/home" title={`FigPedia Content`}></Menu> */}
                                <Menu path="/Ecom-store" title={`E-commerce Store`}></Menu>
                                {/* <Menu path="/frontend/terms-conditions" title={`Terms And Conditions`}></Menu> */}
                                {/* <Menu path="/frontend/privacy-policy" title={`Privacy Policy`}></Menu> */}
                                <Menu path="/pages/list/1" title={`Pages`}></Menu>
                                <Menu path="/subscription-content/list/1" title={`Subscription Content`}></Menu>
                                <Menu path="/faq-feature/list/1" title={`FAQs`}></Menu>
                                {/* <Menu path="/settings/faq/list/1" title={`FAQs remove`}></Menu> */}
                            </Dropdown>

                            {/* <Dropdown title={`Settings`} icon="simple-icons:frontendmentor" iconifyIcon="true">
                                
                            </Dropdown> */}
                        </DropDownMenu>

                        <ul>
                            <li>
                                <a title="Logout" onClick={() => {reset()}}>
                                    <span className="icon-log-out"></span>
                                    <span className="menu_txt">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="version">
                    <span className="vs-mobile">LT-M-22</span>
                    <span className="vs-web">V LT-MAR-22</span>
                </div> */}
                </nav>
            </div>
        </>
    );
}
export default Header;
