import React, { Suspense, useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import Loader, { PageLoader } from "../components/loader/loader";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import Header from "./header";
import Footer from "./footer";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";


const Dashboard = React.lazy(() => import("../pages/dashboard"));
const UsersSellers = React.lazy(() => import("../pages/user-sellers/list"));
const UsersSellersView = React.lazy(() => import("../pages/user-sellers/view"));

const UsersModerators = React.lazy(() => import("../pages/user-moderators/list"));
const UsersModeratorsView = React.lazy(() => import("../pages/user-moderators/view"));
const UsersLogin = React.lazy(() => import("../pages/users/users-login"));
const SellerRequest = React.lazy(() => import("../pages/users/seller-request"));
const OrdersPlaced = React.lazy(() => import("../pages/users/orders-placed"));
const AuctionsParticipat = React.lazy(() => import("../pages/users/auctions-participated"));
const ProductList = React.lazy(() => import("../pages/users/products-listed"));
const StoreSubscript = React.lazy(() => import("../pages/users/store-subscriptions"));
const Advertisements = React.lazy(() => import("../pages/users/advertisements"));
const Invoicing = React.lazy(() => import("../pages/users/invoicing"));
const AddressesAdded = React.lazy(() => import("../pages/users/addresses-added"));

const CountryList = React.lazy(() => import("../pages/country-assignments/list"));
const CountryAdd = React.lazy(() => import("../pages/country-assignments/add"));
const CountryEdit = React.lazy(() => import("../pages/country-assignments/edit"));

const Badges = React.lazy(() => import("../pages/users/badges"));

const StoreManage = React.lazy(() => import("../pages/fees-management/store-manage"));
const MarginManage = React.lazy(() => import("../pages/fees-management/margin-manage"));
const AdvertisementManageList = React.lazy(() => import("../pages/fees-management/advertisement-manage-list"));
const FeesManagementEdit = React.lazy(() => import("../pages/fees-management/edit"));
const FeesManagementAdd = React.lazy(() => import("../pages/fees-management/add"));


const CategoryAdd = React.lazy(() => import("../pages/category/add"));
const CategoryEdit = React.lazy(() => import("../pages/category/edit"));
const CategoryList = React.lazy(() => import("../pages/category/list"));
const Categoryview = React.lazy(() => import("../pages/category/view"));

// const ChannelAdd = React.lazy(() => import("../pages/channel/add"));
const ChannelEdit = React.lazy(() => import("../pages/channel/edit"));
const ChannelList = React.lazy(() => import("../pages/channel/list"));
const Channelview = React.lazy(() => import("../pages/channel/view"));

const SubForumAdd = React.lazy(() => import("../pages/sub-forum/add"));
const SubForumEdit = React.lazy(() => import("../pages/sub-forum/edit"));
const SubForumList = React.lazy(() => import("../pages/sub-forum/list"));
const SubForumview = React.lazy(() => import("../pages/sub-forum/view"));

const Topic = React.lazy(() => import("../pages/topic/list"));
const TopicView = React.lazy(() => import("../pages/topic/view"));

const CountryList1  =React.lazy(() => import("../pages/country/list"));
const CountryListView = React.lazy(() => import("../pages/country/view"));
const CountryListEdit = React.lazy(() => import("../pages/country/edit"));
const CountryAdd1 =React.lazy(() => import("../pages/country/add"));


const StateList1  =React.lazy(() => import("../pages/state/list"));
const StateListView = React.lazy(() => import("../pages/state/view"));
const StateListEdit = React.lazy(() => import("../pages/state/edit"));
const StateAdd1 =React.lazy(() => import("../pages/state/add"));

const CityList1  =React.lazy(() => import("../pages/city/list"));
const CityListView = React.lazy(() => import("../pages/city/view"));
const CityListEdit = React.lazy(() => import("../pages/city/edit"));
const CityAdd1 =React.lazy(() => import("../pages/city/add"));

const ShippingList1  =React.lazy(() => import("../pages/shipping-gateway/list"));
const ShippingListView = React.lazy(() => import("../pages/shipping-gateway/view"));
const ShippingListEdit = React.lazy(() => import("../pages/shipping-gateway/edit"));
const ShippingAdd1 =React.lazy(() => import("../pages/shipping-gateway/add"));

const FeatureFaqList1  =React.lazy(() => import("../pages/faqfeature/list"));
const FeatureFaqListView = React.lazy(() => import("../pages/faqfeature/view"));
const FeatureFaqListEdit = React.lazy(() => import("../pages/faqfeature/edit"));
const FeatureFaqAdd1 =React.lazy(() => import("../pages/faqfeature/add"));

const FaqList1  =React.lazy(() => import("../pages/faq/list"));
const FaqListView = React.lazy(() => import("../pages/faq/view"));
const FaqListEdit = React.lazy(() => import("../pages/faq/edit"));
const FaqAdd1 =React.lazy(() => import("../pages/faq/add"));

const PriceList1  =React.lazy(() => import("../pages/pricemanagement/list"));
const PriceListView = React.lazy(() => import("../pages/pricemanagement/view"));
const PriceListEdit = React.lazy(() => import("../pages/pricemanagement/edit"));
const PriceAdd1 =React.lazy(() => import("../pages/pricemanagement/add"));

const PagesList1  =React.lazy(() => import("../pages/pages/list"));
const PagesListView = React.lazy(() => import("../pages/pages/view"));
const PagesListEdit = React.lazy(() => import("../pages/pages/edit"));
const PagesAdd1 =React.lazy(() => import("../pages/pages/add"));

const BadgesList1  =React.lazy(() => import("../pages/badges/list"));
const BadgesListView = React.lazy(() => import("../pages/badges/view"));
const BadgesListEdit = React.lazy(() => import("../pages/badges/edit"));
const BadgesAdd1 =React.lazy(() => import("../pages/badges/add"));

const BannerList1  =React.lazy(() => import("../pages/banner/list"));
const BannerListView = React.lazy(() => import("../pages/banner/view"));
const BannerListEdit = React.lazy(() => import("../pages/banner/edit"));
const BannerAdd1 =React.lazy(() => import("../pages/banner/add"));

const CultivarList1  =React.lazy(() => import("../pages/cultivar/list"));
const CultivarListView = React.lazy(() => import("../pages/cultivar/view"));
const CultivarListEdit = React.lazy(() => import("../pages/cultivar/edit"));
const CultivarAdd1 =React.lazy(() => import("../pages/cultivar/add"));

const ForumContentList1  =React.lazy(() => import("../pages/forumcontent/list"));
const ForumContentListView = React.lazy(() => import("../pages/forumcontent/view"));
const ForumContentListEdit = React.lazy(() => import("../pages/forumcontent/edit"));
const ForumContentAdd1 =React.lazy(() => import("../pages/forumcontent/add"));

const FigmediaList1  =React.lazy(() => import("../pages/figpediacontent/list"));
const FigmediaListView = React.lazy(() => import("../pages/figpediacontent/view"));
const FigmediaListEdit = React.lazy(() => import("../pages/figpediacontent/edit"));
const FigmediaAdd1 =React.lazy(() => import("../pages/figpediacontent/add"));

const SubscriptioncontentList1  =React.lazy(() => import("../pages/subscriptioncontent/list"));
const SubscriptioncontentListView = React.lazy(() => import("../pages/subscriptioncontent/view"));
const SubscriptioncontentListEdit = React.lazy(() => import("../pages/subscriptioncontent/edit"));
const SubscriptioncontentAdd1 =React.lazy(() => import("../pages/subscriptioncontent/add"));

const SubscriptionplanList1  =React.lazy(() => import("../pages/subscriptionplan/list"));
const SubscriptionplanListView = React.lazy(() => import("../pages/subscriptionplan/view"));
const SubscriptionplanListEdit = React.lazy(() => import("../pages/subscriptionplan/edit"));
const SubscriptionplanAdd1 =React.lazy(() => import("../pages/subscriptionplan/add"));

const ListingManagementList1  =React.lazy(() => import("../pages/ListingManagement/list"));
const ListingManagementListView = React.lazy(() => import("../pages/ListingManagement/view"));
const ListingManagementListEdit = React.lazy(() => import("../pages/ListingManagement/edit"));
const ListingManagementAdd1 =React.lazy(() => import("../pages/ListingManagement/add"));

const UserLogs = React.lazy(() => import("../pages/userlogs/list"));



function Layout() {
    const context = useContext(UserContext);
    // const { setSocket, auth } = context;
    // const authLocal = localStorage.getItem("token");
    // useEffect(() => {
    //     if (auth && authLocal != undefined) {
    //         const connection = io(`${socketConnectionURL}?type=admin&token=${authLocal}`, { transports: ["websocket", "polling"] });
    //         // console.log(connection)
    //         setSocket(connection || {});

    //         connection.on("connect", () => { });

    //         connection.on("disconnect", () => { });
    //         return () => {
    //             connection.off("connect");
    //             connection.off("disconnect");
    //         };
    //     }
    // }, [auth, authLocal]);

    return (
        <div
            className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? "mini-sidebar" : ""}      
    `}
        >
            <ToastContainer />
            <Header />
            <div className="all-content-wrapper">
                <TopNavbar />
                <Loader />

                <div className="mainContent px-3 mt-4">
                    <div className="container-fluid">
                        <Suspense fallback={<PageLoader />}>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/users-sellers/list/:page" element={<UsersSellers />} />
                                <Route path="/users-sellers/view/:id" element={<UsersSellersView />} />
                                <Route path="/user-moderators/list/:page" element={<UsersModerators />} />
                                <Route path="/user-moderators/view/:id" element={<UsersModeratorsView />} />
                                
                                <Route path="/users/users-login" element={<UsersLogin />} />
                                <Route path="/users/seller-request" element={<SellerRequest />} />
                                <Route path="/users/orders-placed" element={<OrdersPlaced />} />
                                <Route path="/users/auctions-participated" element={<AuctionsParticipat />} />
                                <Route path="/users/products-listed" element={<ProductList />} />
                                <Route path="/users/store-subscriptions" element={<StoreSubscript />} />
                                <Route path="/users/advertisements" element={<Advertisements />} />
                                <Route path="/users/invoicing" element={<Invoicing />} />
                                <Route path="/users/addresses-added" element={<AddressesAdded />} />
                                
                                
                                <Route path="/country-assignments/list" element={<CountryList />} />
                                <Route path="/country-assignments/add" element={<CountryAdd />} />
                                <Route path="/country-assignments/edit" element={<CountryEdit />} />

                                <Route path="/Country/list/:page" element={<CountryList1 />} />
                                <Route path="/Country/view/:id" element={<CountryListView />} />
                                <Route path="/Country/edit/:id" element={<CountryListEdit />} />
                                <Route path="/Country/add" element={<CountryAdd1 />} />

                                <Route path="/state/list/:page" element={<StateList1 />} />
                                <Route path="/state/view/:id" element={<StateListView />} />
                                <Route path="/state/edit/:id" element={<StateListEdit />} />
                                <Route path="/state/add" element={<StateAdd1 />} />

                                <Route path="/city/list/:page" element={<CityList1 />} />
                                <Route path="/city/view/:id" element={<CityListView />} />
                                <Route path="/city/edit/:id" element={<CityListEdit />} />
                                <Route path="/city/add" element={<CityAdd1 />} />

                                <Route path="/Shipping-gateways/list/:page" element={<ShippingList1 />} />
                                <Route path="/Shipping-gateways/view/:id" element={<ShippingListView />} />
                                <Route path="/Shipping-gateways/edit/:id" element={<ShippingListEdit />} />
                                <Route path="/Shipping-gateways/add" element={<ShippingAdd1 />} />

                                <Route path="/faq-feature/list/:page" element={<FeatureFaqList1 />} />
                                <Route path="/faq-feature/view/:id/:page" element={<FeatureFaqListView />} />
                                <Route path="/faq-feature/edit/:id" element={<FeatureFaqListEdit />} />
                                <Route path="/faq-feature/add" element={<FeatureFaqAdd1 />} />

                                <Route path="/faq/list/:page" element={<FaqList1 />} />
                                <Route path="/faq/view/:id" element={<FaqListView />} />
                                <Route path="/faq/edit/:id/:ids" element={<FaqListEdit />} />
                                <Route path="/faq/add/:id" element={<FaqAdd1 />} />

                                <Route path="/price-management/list/:page" element={<PriceList1 />} />
                                <Route path="/price-management/view/:id" element={<PriceListView />} />
                                <Route path="/price-management/edit/:id" element={<PriceListEdit />} />
                                <Route path="/price-management/add" element={<PriceAdd1 />} />

                                <Route path="/pages/list/:page" element={<PagesList1 />} />
                                <Route path="/pages/view/:id" element={<PagesListView />} />
                                <Route path="/pages/edit/:id" element={<PagesListEdit />} />
                                <Route path="/pages/add" element={<PagesAdd1 />} />

                                <Route path="/banner/list/:page" element={<BannerList1 />} />
                                <Route path="/banner/view/:id" element={<BannerListView />} />
                                <Route path="/banner/edit/:id" element={<BannerListEdit />} />
                                <Route path="/banner/add" element={<BannerAdd1 />} />

                                <Route path="/badges/list/:page" element={<BadgesList1 />} />
                                <Route path="/badges/view/:id" element={<BadgesListView />} />
                                <Route path="/badges/edit/:id" element={<BadgesListEdit />} />
                                <Route path="/badges/add" element={<BadgesAdd1 />} />

                                {/* <Route path="/forumcontent/list/:page" element={<ForumContentList1 />} /> */}
                                {/* <Route path="/forumcontent/view/home" element={<ForumContentListView />} /> */}
                                <Route path="/forumcontent/edit/home" element={<ForumContentListEdit />} />
                                {/* <Route path="/forumcontent/add" element={<ForumContentAdd1 />} /> */}

                                {/* <Route path="/fig-pedia-content/list/:page" element={<FigmediaList1 />} /> */}
                                {/* <Route path="/fig-pedia-content/view/:id" element={<FigmediaListView />} /> */}
                                <Route path="/fig-pedia-content/edit/home" element={<FigmediaListEdit />} />
                                {/* <Route path="/fig-pedia-content/add" element={<FigmediaAdd1 />} /> */}

                                <Route path="/subscription-content/list/:page" element={<SubscriptioncontentList1 />} />
                                <Route path="/subscription-content/view/:id" element={<SubscriptioncontentListView />} />
                                <Route path="/subscription-content/edit/:id" element={<SubscriptioncontentListEdit />} />
                                <Route path="/subscription-content/add" element={<SubscriptioncontentAdd1 />} />

                                <Route path="/subscription-plan/list/:page" element={<SubscriptionplanList1 />} />
                                <Route path="/subscription-plan/view/:id" element={<SubscriptionplanListView />} />
                                <Route path="/subscription-plan/edit/:id" element={<SubscriptionplanListEdit />} />
                                <Route path="/subscription-plan/add" element={<SubscriptionplanAdd1 />} />
                                
                                <Route path="/users/badges" element={<Badges />} />

                                <Route path="/fees-management/store-manage" element={<StoreManage />} />
                                <Route path="/fees-management/margin-manage" element={<MarginManage />} />
                                <Route path="/fees-management/advertisement-manage/:page" element={<AdvertisementManageList />} />
                                <Route path="/fees-management/edit/:id" element={<FeesManagementEdit />} />
                                <Route path="/fees-management/add" element={<FeesManagementAdd />} />

                                <Route path="/category/add" element={<CategoryAdd />} />
                                <Route path="/category/edit/:id" element={<CategoryEdit />} />
                                <Route path="/category/list/:page" element={<CategoryList />} />
                                <Route path="/category/view/:id" element={<Categoryview />} />

                                {/* <Route path="/channel/add" element={<ChannelAdd />} /> */}
                                <Route path="/channel/edit/:id" element={<ChannelEdit />} />
                                <Route path="/channel/list/:page" element={<ChannelList />} />
                                <Route path="/channel/view/:id" element={<Channelview />} />

                                <Route path="/sub-forum/add" element={<SubForumAdd />} />
                                <Route path="/sub-forum/edit/:id" element={<SubForumEdit />} />
                                <Route path="/sub-forum/list/:page" element={<SubForumList />} />
                                <Route path="/sub-forum/view/:id" element={<SubForumview />} />

                                <Route path="/topic/list/:page" element={<Topic />} />
                                <Route path="/topic/view/:id" element={<TopicView />} />

                                <Route path="/cultivar/add" element={<CultivarAdd1 />} />
                                <Route path="/cultivar/edit/:id" element={<CultivarListEdit />} />
                                <Route path="/cultivar/list/:page" element={<CultivarList1 />} />
                                <Route path="/cultivar/view/:id" element={<CultivarListView />} />

                                <Route path="/listing-management/add" element={<ListingManagementAdd1 />} />
                                <Route path="/listing-management/edit/:id" element={<ListingManagementListEdit />} />
                                <Route path="/listing-management/list/:page" element={<ListingManagementList1 />} />
                                <Route path="/listing-management/view/:id" element={<ListingManagementListView />} />

                                <Route path ="/userlogs/:page" element={<UserLogs/>}/>

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Suspense>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Layout;
