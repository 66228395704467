let ROOT_URL = "";
let BASE_URL = "";
let socketConnectionURL = "";
// console.log(process.env.REACT_APP_SERVER);
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://localhost/api/v1";
  BASE_URL = "https://localhost/api/v1";
  socketConnectionURL = "https://localhost";
} else {
  ROOT_URL = "https://ourfigs.appdevelop.in/api/v1";
  BASE_URL = "https://ourfigs.appdevelop.in/api/v1";
  socketConnectionURL = "https://ourfigs.appdevelop.in";
}
export { BASE_URL, socketConnectionURL }
export default ROOT_URL;